@import "../../../Config";

.static-website-page-container {
	width: 100%;
	height: 100%;

	.static-website-page-layout {
		&.ant-layout {
			min-height: 100%;
			width: 100%;

			.static-website-page-wrapper {
				&.ant-layout {
					height: 100%;
					width: 100%;
					transition: all 0.2s ease;

					.static-website-page-header {
						&.ant-layout-header {
							position: fixed;
							top: 0px;
							left: 0px;
							right: 0px;
							padding: 0px 24px;
							width: 100%;
							color: $themeSecondaryTextColor;
							z-index: 1;
							border-bottom: 1px solid $themePrimaryColor;
							height: 64px;
							line-height: 64px;
							user-select: none;

							.logo-container {
								display: inline-flex;
								align-items: center;
								color: $themeFixedWhiteColor;
								font-size: 16px;
								cursor: pointer;

								.logo {
									height: 36px;
									width: 36px;
									margin-right: 12px;
								}
							}

							.menu-container {
								position: relative;
								float: right;
								user-select: none;

								.trigger {
									color: $themeFixedWhiteColor;
								}

								.ant-menu {
									&.ant-menu-horizontal {
										font-size: 16px;
										background-color: transparent;
										border: none;

										.ant-menu-item {
											border: none;
											color: $themeFixedWhiteColor;
											margin: -1px 0px 0px 0px;
											padding: 0px 16px;

											a {
												color: $themeFixedWhiteColor;
											}

											&:hover,
											&.ant-menu-item-active,
											&.ant-menu-item-selected {
												border: none;
												color: $themeFixedWhiteColor;

												a {
													color: $themeFixedWhiteColor;
												}
											}

											&.ant-menu-item-selected {
												color: $themeFixedWhiteColor;
												background-color: $themePrimaryColor;
												margin-bottom: 1px;

												a {
													color: $themeFixedWhiteColor;
												}
											}
										}
									}
								}

								.menu-dropdown {
									&.ant-dropdown {
										transition: 0.2s display ease;

										.ant-dropdown-menu {
											&.ant-dropdown-menu-vertical {
												width: 200px;
												padding: 8px 0px;

												.ant-dropdown-menu-item {
													height: 48px;
													line-height: 36px;
													color: $themeSecondaryTextColor;

													a {
														color: $themeSecondaryTextColor;
													}

													&.ant-dropdown-menu-item-selected {
														color: $themeFixedWhiteColor;

														&:not(.ant-dropdown-menu-item-active) {
															color: $themeFixedWhiteColor;
															background-color: $themePrimaryColor;
														}

														&.ant-dropdown-menu-item-active {
															color: $themeFixedWhiteColor;
														}
													}

													&.ant-dropdown-menu-item-active {
														color: $themeSecondaryTextColor;

															&:not(.ant-dropdown-menu-item-selected) {
																color: $themeFixedWhiteColor;

																a {
																	color: $themeFixedWhiteColor;
																}
															}
													}

													&:hover {
														color: $themeSecondaryTextColor;
														background-color: $themePrimaryColor;
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.static-website-page-content {
						&.ant-layout-content {
							margin-top: 64px;
							height: calc(100% - 64px);
							width: 100%;
							background-color: $themeWhiteColor;
							color: $themeSecondaryTextColor;
						}
					}

					.static-website-page-footer {
						&.ant-layout-footer {
							padding: 144px 64px 24px 64px;
							background-image: url('https://schedulify.app/images/website/FooterOverlay.webp'), url('https://schedulify.app/images/website/FooterOverlay.png');
							background-color: $themeWhiteColor;
							background-repeat: no-repeat;
							background-size: cover;
							background-position: center 0px;
							
							.footer-title-container {
								.footer-title {
									font-size: 48px;
									color: $themeFixedWhiteColor;
								}

								.footer-social-links-container {
									.footer-social-link {
										.anticon {
											color: $themeFixedWhiteColor;
											vertical-align: middle;

											svg {
												height: 32px;
												width: 32px;
											}
										}
									}
								}

								.footer-subtitle {
									font-size: 14px;
									color: $themeFixedWhiteColor;
								}
							}

							.footer-links-container {
								height: 100%;
								margin-top: 24px;

								.footer-link-title {
									font-size: 18px;
									margin-bottom: 24px;
									color: $themeFixedWhiteColor;

									a {
										color: $themeFixedWhiteColor;
									}
								}

								.ant-divider {
									margin: 0px 0px 24px 0px;
									border: 0.05rem solid $themeFixedWhiteColor;
									color: $themeFixedWhiteColor;
									min-width: 50px;
									width: 50px;
								}

								.footer-link {
									font-size: 16px;
									cursor: pointer;
									color: $themeFixedWhiteColor;

									.ant-tag {
										border-color: $themeFixedWhiteColor;
										background-color: $themeContainerBackgroundColor;
										color: $themeFixedWhiteColor;
										padding: 4px 12px;
										border-radius: 24px;
									}

									a {
										color: $themeFixedWhiteColor;
									}

									&:not(:last-of-type) {
										margin-bottom: 12px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
