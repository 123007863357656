@import "../../../../Config";

.loading-container {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	background: rgba($color: $themePrimaryColor, $alpha: 0.4);
	z-index: 99999;

	.loading {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -35px 0 0 -56px;
		width: 112px;
		height: 70px;

		&:before,
		&:after {
			display: table;
			content: "";
		}

		&:after {
			clear: both;
		}

		.finger {
			float: left;
			margin: 0px 2px 0px 0px;
			width: 20px;
			height: 100%;
		}

		.finger-1 {
			animation: finger-1-animation 2s infinite ease-out;

			span {
				animation: finger-1-animation-span 2s infinite ease-out;
			}

			i {
				animation: finger-1-animation-i 2s infinite ease-out;
			}
		}

		.finger-2 {
			animation: finger-2-animation 2s infinite ease-out;

			span {
				animation: finger-2-animation-span 2s infinite ease-out;
			}

			i {
				animation: finger-2-animation-i 2s infinite ease-out;
			}
		}
		.finger-3 {
			animation: finger-3-animation 2s infinite ease-out;

			span {
				animation: finger-3-animation-span 2s infinite ease-out;
			}

			i {
				animation: finger-3-animation-i 2s infinite ease-out;
			}
		}
		.finger-4 {
			animation: finger-4-animation 2s infinite ease-out;

			span {
				animation: finger-4-animation-span 2s infinite ease-out;
			}

			i {
				animation: finger-4-animation-i 2s infinite ease-out;
			}
		}
		.finger-item {
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: 6px 6px 8px 8px;
			background: $themeContainerBackgroundColor;

			span {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: auto;
				padding: 5px 5px 0px 5px;

				&:before,
				&:after {
					content: "";
					position: relative;
					display: block;
					margin: 0px 0px 2px 0px;
					width: 100%;
					height: 2px;
					background: $themePrimaryColor;
				}
			}

			i {
				position: absolute;
				left: 3px;
				bottom: 3px;
				width: 14px;
				height: 14px;
				width: 14px;
				height: 14px;
				border-radius: 10px 10px 7px 7px;
				background: $themePrimaryColor;
			}
		}

		.last-finger {
			position: relative;
			float: left;
			width: 24px;
			height: 100%;
			overflow: hidden;
		}

		.last-finger-item {
			position: absolute;
			right: 0;
			top: 32px;
			width: 110%;
			height: 20px;
			border-radius: 0px 5px 14px 0px;
			background: $themeContainerBackgroundColor;
			animation: finger-5-animation 2s infinite linear;

			i {
				position: absolute;
				left: 0;
				top: -8px;
				width: 22px;
				height: 8px;
				background: $themeContainerBackgroundColor;
				overflow: hidden;

				&:after {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 34px;
					height: 20px;
					border-radius: 0 0 15px 15px;
					background: $themePrimaryColor;
				}
			}
		}
	}
}

@keyframes finger-1-animation {
	0% {
		padding: 12px 0 5px 0;
	}

	20% {
		padding: 12px 0 5px 0;
	}

	29% {
		padding: 4px 0 24px 0;
	}

	35% {
		padding: 4px 0 24px 0;
	}

	41% {
		padding: 12px 0 5px 0;
	}

	100% {
		padding: 12px 0 5px 0;
	}
}
@keyframes finger-1-animation-span {
	0% {
		top: 0;
	}

	20% {
		top: 0;
	}

	29% {
		top: -7px;
	}

	35% {
		top: -7px;
	}

	41% {
		top: 0;
	}

	100% {
		top: 0;
	}
}
@keyframes finger-1-animation-i {
	0% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	20% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	29% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	35% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	41% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	100% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}
}

@keyframes finger-2-animation {
	0% {
		padding: 6px 0 2px 0;
	}

	24% {
		padding: 6px 0 2px 0;
	}

	33% {
		padding: 2px 0 16px 0;
	}

	39% {
		padding: 2px 0 16px 0;
	}

	45% {
		padding: 6px 0 2px 0;
	}

	100% {
		padding: 6px 0 2px 0;
	}
}
@keyframes finger-2-animation-span {
	0% {
		top: 0;
	}

	24% {
		top: 0;
	}

	33% {
		top: -7px;
	}

	39% {
		top: -7px;
	}

	45% {
		top: 0;
	}

	100% {
		top: 0;
	}
}
@keyframes finger-2-animation-i {
	0% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	24% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	33% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	39% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	45% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	100% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}
}

@keyframes finger-3-animation {
	0% {
		padding: 0 0 0 0;
	}

	28% {
		padding: 0 0 0 0;
	}

	37% {
		padding: 0 0 12px 0;
	}

	43% {
		padding: 0 0 12px 0;
	}

	49% {
		padding: 0 0 0 0;
	}

	100% {
		padding: 0 0 0 0;
	}
}
@keyframes finger-3-animation-span {
	0% {
		top: 0;
	}

	28% {
		top: 0;
	}

	37% {
		top: -7px;
	}

	43% {
		top: -7px;
	}

	49% {
		top: 0;
	}

	100% {
		top: 0;
	}
}
@keyframes finger-3-animation-i {
	0% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	28% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	37% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	43% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	49% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	100% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}
}

@keyframes finger-4-animation {
	0% {
		padding: 8px 0 3px 0;
	}

	32% {
		padding: 8px 0 3px 0;
	}

	41% {
		padding: 4px 0 20px 0;
	}

	47% {
		padding: 4px 0 20px 0;
	}

	53% {
		padding: 8px 0 3px 0;
	}

	100% {
		padding: 8px 0 3px 0;
	}
}
@keyframes finger-4-animation-span {
	0% {
		top: 0;
	}

	32% {
		top: 0;
	}

	41% {
		top: -7px;
	}

	47% {
		top: -7px;
	}

	53% {
		top: 0;
	}

	100% {
		top: 0;
	}
}
@keyframes finger-4-animation-i {
	0% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	32% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	41% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	47% {
		bottom: 8px;
		height: 12px;
		border-radius: 7px 7px 4px 4px;
	}

	53% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}

	100% {
		bottom: 3px;
		height: 14px;
		border-radius: 10px 10px 7px 7px;
	}
}

@keyframes finger-5-animation {
	0% {
		top: 32px;
		right: 0;
		border-radius: 0 5px 14px 0;
		transform: rotate(0deg);
	}

	34% {
		top: 32px;
		right: 0;
		border-radius: 0 5px 14px 0;
		transform: rotate(0deg);
	}

	43% {
		top: 20px;
		right: 2px;
		border-radius: 0 8px 20px 0;
		transform: rotate(-12deg);
	}

	50% {
		top: 20px;
		right: 2px;
		border-radius: 0 8px 20px 0;
		transform: rotate(-12deg);
	}

	60% {
		top: 32px;
		right: 0;
		border-radius: 0 5px 14px 0;
		transform: rotate(0deg);
	}

	100% {
		top: 32px;
		right: 0;
		border-radius: 0 5px 14px 0;
		transform: rotate(0deg);
	}
}
