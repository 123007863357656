$themeFontFamily: 'Source Sans Pro', sans-serif, Helvetica, Arial;
$themeFixedFontFamily: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;

$themeFixedWhiteColor: #FFFFFF;
$themeFixedBlackColor: #212121;

$themePrimaryColor: #1890FF;
$themeSecondaryColor: #E6F7FF;

$themeBorderColor: #AAAAAA;
$themeSiderMenuBackgroundColor: #FFFFFF;

$themeGreenColor: #27AE60;
$themeOrangeColor: #FFB200;
$themeRedColor: #FF4D4F;

$themeFacebookColor: #385898;
$themeTwitterColor: #1B95E0;

:root {
	--themePrimaryColor: #1890FF;
	--themeRedColor: #FF4D4F;
	--themeSecondaryColor: #E6F7FF;
	--themeWhiteColor: #FFFFFF;
	--themeBlackColor: #212121;
	--themeContainerBackgroundColor: #F0F2F5;
	--themePrimaryTextColor: rgba(0, 0, 0, 0.65);
	--themeSecondaryTextColor: rgba(0, 0, 0, 0.85);
	--themeBorderColor: #AAAAAA;
	--themeSiderMenuBackgroundColor: #FFFFFF;
}

@media (prefers-color-scheme: light) {
	:root {
		--themeSecondaryColor: #E6F7FF;
		--themeWhiteColor: #FFFFFF;
		--themeBlackColor: #212121;
		--themeContainerBackgroundColor: #F0F2F5;
		--themePrimaryTextColor: rgba(0, 0, 0, 0.65);
		--themeSecondaryTextColor: rgba(0, 0, 0, 0.85);
		--themeBorderColor: #AAAAAA;
		--themeSiderMenuBackgroundColor: #FFFFFF;
	}
}

@media (prefers-color-scheme: dark) {
	:root {
		--themeSecondaryColor: #111B26;
		--themeWhiteColor: #212121;
		--themeBlackColor: #FFFFFF;
		--themeContainerBackgroundColor: #313131;
		--themePrimaryTextColor: rgba(255, 255, 255, 0.65);
		--themeSecondaryTextColor: rgba(255, 255, 255, 0.85);
		--themeBorderColor: #E6F7FF;
		--themeSiderMenuBackgroundColor: #001529;
	}
}

$themeSecondaryColor: var(--themeSecondaryColor);
$themeWhiteColor: var(--themeWhiteColor);
$themeBlackColor: var(--themeBlackColor);
$themeContainerBackgroundColor: var(--themeContainerBackgroundColor);
$themePrimaryTextColor: var(--themePrimaryTextColor);
$themeSecondaryTextColor: var(--themeSecondaryTextColor);
$themeBorderColor: var(--themeBorderColor);
$themeSiderMenuBackgroundColor: var(--themeSiderMenuBackgroundColor);