@import "../../../Config";

.email-unverified-alert {
	&.ant-alert {
		z-index: 1;
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		min-height: 64px;
		display: flex;
		align-items: center;

		.ant-alert-message {
			font-size: 14px;
			color: $themeFixedBlackColor;

			.anticon {
				color: $themeRedColor;

				svg {
					color: $themeRedColor;
				}
			}

			.ant-btn {
				&.ant-btn-link {
					padding-top: 0px;
					padding-left: 12px;
					padding-right: 0px;
					padding-bottom: 0px;
					height: 18px;
					line-height: 16px;

					&:not(.ant-btn-loading) {
						padding-left: 0px;
					}
				}
			}
		}
	}
}

.internet-connected-result {
	&.ant-result {
		height: 100%;
		width: 100%;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.ant-result-title,
		.ant-result-subtitle {
			color: $themePrimaryTextColor;
		}

		.ant-result-content {
			background-color: $themeContainerBackgroundColor;
			margin-top: 48px;

			.ant-typography {
				&:not(.link) {
					color: $themePrimaryTextColor;
				}

				&:last-child {
					margin-bottom: 0px;
				}
			}

			.error-icon {
				color: $themeRedColor;
			}
		}
	}
}