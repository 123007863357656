@import "../../../Config";

.static-website-team-page-container {
	height: 100%;
	width: 100%;
	padding: 24px 36px;

	.team-page-content {
		font-size: 18px;
		color: $themePrimaryTextColor;
		max-width: 1199px;
		margin: 0px auto;

		.team-page-title {
			font-size: 42px;
			color: $themeSecondaryTextColor;
			margin-top: 0px;
			margin-bottom: 24px;
			text-align: center;
		}

		.team-page-row {
			display: flex;

			&:nth-child(1) {
				.team-page-column {
					&.description-column {
						padding-right: 24px;
					}
				}
			}

			&:nth-child(3) {
				flex-direction: row-reverse;

				.team-page-column {
					&.description-column {
						padding-left: 24px;
					}
				}
			}

			.team-page-column {
				&.description-column {
					width: calc(100% - 270px);

					.team-founder-title {
						margin-top: -12px;
					}
				}

				&.card-column {
					width: 270px;

					.team-card {
						&.ant-card {
							width: 270px;
							margin: 0px 0px 0px auto;
							text-align: center;

							.ant-card-cover {
								.team-card-banner {
									height: 270px;
									width: 270px;
								}
							}

							.ant-card-body {
								.ant-card-meta {
									.ant-card-meta-detail {
										.ant-card-meta-title {
											font-size: 24px;
											color: $themeSecondaryTextColor;
										}

										.ant-card-meta-description {
											.team-card-description {
												.team-card-social-icons-container {
													margin-top: 12px;

													.anticon {
														color: $themeSecondaryTextColor;
														border: 1px solid $themeSecondaryTextColor;
														border-radius: 50%;
														padding: 8px;
														cursor: pointer;

														&.team-card-social-icon {
															display: inline-flex;
															justify-content: center;
															align-items: center;
															height: 36px;
															width: 36px;

															svg {
																height: 24px;
																width: 24px;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 767px) {
			.team-page-row {
				flex-direction: column;

				&:nth-child(1),
				&:nth-child(3) {
					flex-direction: column;
				}

				&:nth-child(1) {
					.team-page-column {
						&.description-column {
							padding-right: 0px;
						}
					}
				}

				&:nth-child(3) {
					.team-page-column {
						&.description-column {
							padding-left: 0px;
						}
					}
				}

				.team-page-column {
					width: 100%;

					&.description-column,
					&.card-column {
						width: 100%;
					}

					&.card-column {
						.team-card {
							&.ant-card {
								margin: 18px auto 6px auto;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 575px) {
			.team-page-row {
				flex-direction: column;

				.team-page-column {
					&.card-column {
						.team-card {
							&.ant-card {
								width: 100%;

								.ant-card-cover {
									.team-card-banner {
										height: 100%;
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
