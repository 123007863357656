@import "~antd/dist/antd.min.css";

@import "./Config";
@import "./Util";

html,
body {
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100%;
	font-family: $themeFontFamily;
}

html {
	--antd-wave-shadow-color: #1890ff;
}

body {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	color: $themePrimaryTextColor;
	background-color: $themeWhiteColor;
}

div,
span,
a,
p,
input,
select,
optgroup,
option,
button,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
ul,
ol,
li,
legend,
fieldset,
hr,
header,
footer,
main,
nav,
h1,
h2,
h3,
h4,
h5,
h6,
form,
article,
aside,
b,
strong,
i,
canvas,
caption,
cite,
colgroup,
dialog,
fieldset,
figcaption,
figure,
iframe,
label,
menu,
menuitem,
picture,
progress,
section,
summary,
sub,
sup,
textarea,
title,
time,
track,
video,
audio,
svg,
path,
text,
rect {
	font-family: $themeFontFamily;
}

a {
	color: $themePrimaryColor;

	&:hover,
	&:focus,
	&:active {
		color: $themePrimaryColor;
	}
}

::selection {
	background: $themePrimaryColor;
	color: $themeFixedWhiteColor;
	-webkit-text-fill-color: $themeFixedWhiteColor;
}

::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
::-ms-input-placeholder,
:-ms-input-placeholder {
	color: $themePrimaryTextColor;
	opacity: 1;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	font-size: 14px;
	font-family: $themeFontFamily;
	-webkit-text-fill-color: $themePrimaryTextColor;
	transition: background-color 5000s ease-in-out 0s;
	box-shadow: none;
	-webkit-box-shadow: none;
}

input:-internal-autofill-selected {
	background-color: transparent;
	background-image: none;
	color: $themePrimaryTextColor;
}
