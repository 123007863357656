@import "./Config";

.ant-layout-header,
.ant-layout-footer {
	color: $themeSecondaryTextColor;
}

.ant-layout-sider {
	background: $themeSiderMenuBackgroundColor;

	.ant-menu {
		background: $themeSiderMenuBackgroundColor;
		color: $themePrimaryTextColor;

		.ant-menu-item-group-title {
			color: $themePrimaryTextColor;
		}

		.ant-menu-item {
			color: $themePrimaryTextColor;

			a {
				color: $themePrimaryTextColor;
			}

			&.ant-menu-item-active {
				color: $themeSecondaryTextColor;

				a {
					color: $themeSecondaryTextColor;
				}
			}

			&.ant-menu-item-selected {
				color: $themeFixedWhiteColor;
				background-color: $themePrimaryColor;

				a {
					color: $themeFixedWhiteColor;
				}
			}
		}

		&.ant-menu-vertical {
			border-right: none;
		}
	}
}

.ant-menu-submenu {
	color: $themePrimaryTextColor;

	.ant-menu-submenu-title,
	.ant-menu-item-group-title {
		color: $themePrimaryTextColor;

		&:hover {
			color: $themePrimaryTextColor;
		}

		.ant-menu-submenu-arrow {
			color: $themePrimaryTextColor;

			&:before,
			&:after {
				color: $themePrimaryTextColor;
				background: $themePrimaryTextColor;
			}
		}
	}

	.ant-menu-item {
		color: $themePrimaryTextColor;

		a {
			color: $themePrimaryTextColor;
		}

		&.ant-menu-item-active {
			color: $themeSecondaryTextColor;

			a {
				color: $themeSecondaryTextColor;
			}
		}

		&.ant-menu-item-selected {
			color: $themeFixedWhiteColor;
			background-color: $themePrimaryColor;

			a {
				color: $themeFixedWhiteColor;
			}
		}
	}

	&.ant-menu-vertical {
		border-right: none;
	}

	&.ant-menu-submenu-selected {
		color: $themePrimaryTextColor;
	}

	&.ant-menu-submenu-open,
	&.ant-menu-submenu-selected {
		color: $themeSecondaryTextColor;
	}
}

.ant-menu {
	&.ant-menu-dark {
		.ant-menu-submenu {
			.ant-menu-submenu-title,
			.ant-menu-item-group-title {
				.ant-menu-submenu-arrow {
					color: $themePrimaryTextColor;

					&:before,
					&:after {
						color: $themePrimaryTextColor;
						background: $themePrimaryTextColor;
					}
				}
			}

			&.ant-menu-submenu-selected,
			&.ant-menu-submenu-active {
				.ant-menu-submenu-title,
				.ant-menu-item-group-title {
					.ant-menu-submenu-arrow {
						color: $themeSecondaryTextColor;

						&:before,
						&:after {
							color: $themeSecondaryTextColor;
							background: $themeSecondaryTextColor;
						}
					}
				}
			}
		}
	}
}

.ant-result {
	.ant-result-title,
	.ant-result-subtitle {
		color: $themePrimaryTextColor;
	}

	.ant-result-content {
		background-color: $themeContainerBackgroundColor;
		margin-top: 48px;
	}
}

.ant-typography {
	&:not(.link) {
		color: $themePrimaryTextColor;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $themeSecondaryTextColor;
		}
	}
}

.ant-message {
	.ant-message-notice {
		.ant-message-notice-content {
			word-break: break-word;
		}
	}
}

.ant-spin {
	&.spin-center {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.ant-drawer {
	.ant-drawer-content-wrapper {
		.ant-drawer-content {
			background-color: $themeWhiteColor;

			.ant-drawer-wrapper-body {
				.ant-drawer-header {
					background: $themeWhiteColor;
					color: $themePrimaryTextColor;
					border-bottom: 1px solid $themeBorderColor;

					.ant-drawer-title,
					.ant-drawer-close {
						color: $themePrimaryTextColor;
					}
				}

				.ant-drawer-footer {
					border-top: 1px solid $themeBorderColor;
				}
			}
		}
	}

	&.ant-drawer-open {
		.ant-drawer-content-wrapper {
			min-width: min-content;
		}
	}
}

.ant-badge {
	color: $themePrimaryTextColor;
}

.ant-dropdown {
	color: $themePrimaryTextColor;

	.ant-dropdown-menu {
		background-color: $themeWhiteColor;
		border: 1px solid $themeBorderColor;

		.ant-dropdown-menu-item-group {
			.ant-dropdown-menu-item-group-title {
				color: $themeSecondaryTextColor;
			}
		}

		.ant-dropdown-menu-item {
			color: $themePrimaryTextColor;

			&:hover {
				background-color: $themeContainerBackgroundColor;
				color: $themePrimaryColor;
			}

			&.red-text {
				color: $themeRedColor;
			}
		}

		.ant-dropdown-menu-submenu {
			.ant-dropdown-menu-submenu-title {
				color: $themePrimaryTextColor;

				.ant-dropdown-menu-submenu-expand-icon {
					.ant-dropdown-menu-submenu-arrow-icon {
						color: $themePrimaryTextColor;

						&:hover {
							color: $themeSecondaryTextColor;
						}
					}
				}
			}

			&.ant-dropdown-menu-submenu-open,
			&.ant-dropdown-menu-submenu-active {
				.ant-dropdown-menu-submenu-title {
					color: $themePrimaryColor;

					.ant-dropdown-menu-submenu-expand-icon {
						.ant-dropdown-menu-submenu-arrow-icon {
							color: $themePrimaryColor;

							&:hover {
								color: $themePrimaryColor;
							}
						}
					}
				}
			}
		}
	}

	.ant-table-filter-dropdown {
		color: $themePrimaryTextColor;
		background-color: $themeWhiteColor;
		border-color: $themeBorderColor;
	}
}

.ant-modal-root {
	.ant-modal-wrap {
		.ant-modal {
			color: $themePrimaryTextColor;

			.ant-modal-header,
			.ant-modal-footer {
				color: $themePrimaryTextColor;
				border-color: $themeBorderColor;
			}

			.ant-modal-content {
				color: $themePrimaryTextColor;
				background-color: $themeWhiteColor;
				border: 1px solid $themeBorderColor;

				.ant-modal-close {
					cursor: auto;
					color: $themePrimaryTextColor;

					.ant-modal-close-x {
						.anticon {
							&.anticon-close {
								&.ant-modal-close-icon {
									color: $themePrimaryTextColor;

									&:hover,
									&:focus {
										color: $themePrimaryColor;
									}

									&:hover {
										cursor: pointer;
									}
								}
							}
						}
					}
				}

				.ant-modal-header,
				.ant-modal-footer {
					background-color: $themeContainerBackgroundColor;

					.ant-modal-title {
						color: $themeSecondaryTextColor;
					}

					.ant-modal-confirm-content {
						color: $themePrimaryTextColor;
					}
				}

				.ant-modal-body {
					color: $themePrimaryTextColor;

					.ant-modal-confirm-body,
					.ant-modal-info-body {
						.anticon {
							color: $themePrimaryColor;
						}
					}

					.ant-modal-confirm-title {
						color: $themeSecondaryTextColor;
					}

					.ant-modal-confirm-content {
						color: $themePrimaryTextColor;
					}
				}
			}
		}
	}
}

.ant-btn {
	&:not(.ant-btn-primary) {
		&:not(.ant-btn-dashed) {
			&:not(.ant-btn-danger) {
				&:not(.ant-btn-link) {
					&:not(.ant-btn-background-ghost) {
						background-color: $themeWhiteColor;
						color: $themeBlackColor;
						border-color: $themeBorderColor;

						&:hover {
							color: $themePrimaryColor;
							border-color: $themePrimaryColor;
						}
					}
				}
			}
		}
	}
}

.ant-btn {
	&.ant-btn-link {
		&:hover {
			color: $themePrimaryColor;

			&.text-underline {
				text-decoration: underline;
			}
		}
	}
}

.ant-btn-primary-disabled,
.ant-btn-primary-disabled.active,
.ant-btn-primary-disabled:active,
.ant-btn-primary-disabled:focus,
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled,
.ant-btn-primary.disabled.active,
.ant-btn-primary.disabled:active,
.ant-btn-primary.disabled:focus,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled],
.ant-btn-primary[disabled].active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
	color: rgba(255, 255, 255, 0.65);
	background-color: $themePrimaryColor;
}

.ant-collapse {
	background-color: $themeContainerBackgroundColor;

	.ant-collapse-item {
		.ant-collapse-header {
			color: $themeSecondaryTextColor;
		}

		.ant-collapse-content {
			background-color: $themeWhiteColor;
			color: $themePrimaryTextColor;
		}
	}
}

.ant-descriptions {
	.ant-descriptions-view {
		.ant-descriptions-row {
			.ant-descriptions-item {
				.ant-descriptions-item-label,
				.ant-descriptions-item-content {
					color: $themePrimaryTextColor;
				}
			}
		}
	}
}

.ant-list {
	color: $themePrimaryTextColor;

	.ant-list-empty-text {
		.ant-empty {
			color: $themePrimaryTextColor;
		}
	}

	.ant-list-items {
		.ant-list-item {
			border-color: $themeBorderColor;
			color: $themePrimaryTextColor;

			.ant-list-item-meta {
				.ant-list-item-meta-content {
					.ant-list-item-meta-title {
						text-transform: capitalize;

						a {
							color: $themePrimaryColor;
						}
					}

					.ant-list-item-meta-description {
						color: $themePrimaryTextColor;
					}
				}
			}

			.ant-list-item-action {
				li {
					color: $themeSecondaryTextColor;

					.ant-list-item-action-split {
						background-color: $themeBorderColor;
					}

					&:hover {
						color: $themePrimaryColor;
					}
				}
			}
		}
	}
}

.ant-list-item {
	color: $themePrimaryTextColor;
	border-color: $themeBorderColor;
}

.ant-list-empty-text {
	color: $themePrimaryTextColor;
}

.ant-pagination {
	&.ant-table-pagination {
		color: $themePrimaryTextColor;

		.ant-pagination-prev,
		.ant-pagination-next {
			color: $themePrimaryTextColor;

			.ant-pagination-item-link {
				color: $themePrimaryTextColor;
				background-color: $themeWhiteColor;
				border-color: $themeBorderColor;
			}

			&:not(.ant-pagination-disabled) {
				&:hover,
				&:focus {
					.ant-pagination-item-link {
						color: $themePrimaryColor;
					}
				}
			}
		}

		.ant-pagination-item {
			background-color: $themeWhiteColor;
			border-color: $themeBorderColor;

			a {
				color: $themePrimaryTextColor;
			}

			&.ant-pagination-item-active {
				border-color: $themePrimaryColor;

				a {
					color: $themePrimaryColor;
				}
			}

			&:hover {
				a {
					color: $themePrimaryColor;
				}
			}
		}
	}
}

.ant-steps {
	.ant-steps-item {
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-title {
					color: $themePrimaryTextColor;

					&:after {
						background-color: $themeBorderColor
					}
				}
			}
		}	

		&:not(.ant-steps-item-process) {
			&:not(.ant-steps-item-active) {
				.ant-steps-item-container {
					.ant-steps-item-icon {
						background-color: $themeContainerBackgroundColor;
						border-color: $themeBorderColor;

						.ant-steps-icon {
							color: $themePrimaryTextColor;
						}
					}
				}
			}
		}
	}
}

.ant-tabs {
	color: $themePrimaryTextColor;

	.ant-tabs-nav-wrap {
		.ant-tabs-tab {
			color: $themeSecondaryTextColor;
			padding: 8px 24px;

			&:hover,
			&.ant-tabs-tab-active {
				color: $themePrimaryColor;
			}

			&.ant-tabs-tab-active {
				background-color: $themeContainerBackgroundColor;
			}
		}
	}

	.ant-tabs-nav-operations {
		color: $themePrimaryTextColor;
	}

	.ant-tabs-tab-arrow-show {
		&.ant-tabs-tab-prev,
		&.ant-tabs-tab-next {
			color: $themePrimaryTextColor;
		}
	}

	&.ant-tabs-left {
		.ant-tabs-nav-wrap {
			.ant-tabs-tab {
				color: $themeSecondaryTextColor;
				padding: 8px 24px;

				&:hover,
				&.ant-tabs-tab-active {
					color: $themePrimaryColor;
				}

				&.ant-tabs-tab-active {
					background-color: $themeContainerBackgroundColor;
				}
			}
		}

		.ant-tabs-nav {
			.ant-tabs-nav-wrap {
				.ant-tabs-nav-list {
					.ant-tabs-tab {
						color: $themeSecondaryTextColor;
						padding: 8px 24px;

						&:hover,
						&.ant-tabs-tab-active {
							color: $themePrimaryColor;
						}

						&.ant-tabs-tab-active {
							background-color: $themeContainerBackgroundColor;
						}

						& + .ant-tabs-tab {
							margin-top: 8px;
						}
					}
				}
			}
		}

		.ant-tabs-content-holder {
			border-color: $themeBorderColor;
		}
	}

	&.ant-tabs-top {
		.ant-tabs-nav {
			&:before {
				border-color: $themeBorderColor;
			}
		}

		.ant-tabs-nav-wrap {
			.ant-tabs-tab {
				color: $themeSecondaryTextColor;
				padding: 8px 16px;

				&:hover,
				&.ant-tabs-tab-active {
					color: $themePrimaryColor;
				}

				&.ant-tabs-tab-active {
					background-color: $themeContainerBackgroundColor;
				}
			}
		}

		.ant-tabs-nav {
			.ant-tabs-nav-wrap {
				.ant-tabs-nav-list {
					.ant-tabs-tab {
						color: $themeSecondaryTextColor;
						padding: 8px 16px;
						margin-right: 0px;

						&:hover,
						&.ant-tabs-tab-active {
							color: $themePrimaryColor;
						}

						&.ant-tabs-tab-active {
							background-color: $themeContainerBackgroundColor;
						}

						& + .ant-tabs-tab {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}

	.ant-tabs-nav {
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				.ant-tabs-tab {
					color: $themeSecondaryTextColor;
					padding: 8px 24px;

					&:hover,
					&.ant-tabs-tab-active {
						color: $themePrimaryColor;
					}

					&.ant-tabs-tab-active {
						background-color: $themeContainerBackgroundColor;
					}
				}
			}
		}
	}
}

.ant-tabs-dropdown {
	color: $themePrimaryTextColor;

	.ant-tabs-dropdown-menu {
		background-color: $themeWhiteColor;

		.ant-tabs-dropdown-menu-item {
			color: $themePrimaryTextColor;

			.anticon {
				margin-right: 12px;
			}

			&:hover {
				background-color: $themeContainerBackgroundColor;
			}
		}
	}

	&:not(.ant-tabs-dropdown-hidden) {
		border-color: $themeBorderColor;
	}
}

.ant-form {
	color: $themePrimaryTextColor;

	.ant-form-item {
		color: $themePrimaryTextColor;

		.ant-form-item-label {
			label {
				color: $themePrimaryTextColor;
			}
		}

		.ant-form-item-control {
			.ant-form-item-control-input {
				.ant-form-item-control-input-content {
					.ant-input-affix-wrapper,
					.ant-input-suffix-wrapper {
						background-color: transparent;
						color: $themePrimaryTextColor;

						.ant-input {
							color: $themePrimaryTextColor;
							caret-color: $themePrimaryTextColor;

							&:disabled,
							&.ant-input-disabled {
								background-color: $themeContainerBackgroundColor;
							}
						}

						.ant-input-prefix,
						.ant-input-suffix {
							color: $themePrimaryTextColor;

							.ant-input-password-icon {
								color: $themePrimaryTextColor;

								&:hover {
									color: $themePrimaryColor;
								}
							}
						}

						&.ant-input-affix-wrapper-disabled,
						&.ant-input-suffix-wrapper-disabled {
							background-color: $themeContainerBackgroundColor;
						}

						&:hover,
						&.ant-input-affix-wrapper-focused,
						&.ant-input-suffix-wrapper-focused {
							.ant-input-prefix,
							.ant-input-suffix {
								color: $themePrimaryColor;
							}
						}

						&.ant-input-affix-wrapper-focused,
						&.ant-input-suffix-wrapper-focused {
							border-color: $themePrimaryColor;
						}
					}

					.ant-input {
						color: $themePrimaryTextColor;
						caret-color: $themePrimaryTextColor;

							&:disabled,
							&.ant-input-disabled {
								background-color: $themeContainerBackgroundColor;
							}
					}

					.ant-input-number {
						color: $themePrimaryTextColor;
						background-color: transparent;

						&:not(:hover),
						&:not(.ant-input-number-focused) {
							border-color: $themeBorderColor;
						}

						&:hover,
						&.ant-input-number-focused {
							border-color: $themePrimaryColor;
						}
					}

					.ant-select {
						&:not(.ant-select-disabled) {
							.ant-select-selector {
								background-color: transparent;
								border-color: $themeBorderColor;
							}
						}

						&.ant-select-disabled {
							.ant-select-selector {
								color: $themePrimaryTextColor;
								background-color: $themeContainerBackgroundColor;
							}
						}

						&:focus,
						&.ant-select-focused {
							&:not(.ant-select-disabled) {
								.ant-select-selector {
									box-shadow: none;
									outline: none;
								}
							}
						}
					}
				}

				.ant-picker {
					color: $themePrimaryTextColor;

					&:not(:hover),
					&:not(.ant-picker-focused) {
						border-color: $themeBorderColor;
					}

					&:hover,
					&.ant-picker-focused {
						border-color: $themePrimaryColor;

						.ant-picker-input {
							.ant-picker-suffix {
								color: $themePrimaryColor;
							}
						}
					}

					&:not(.ant-picker-disabled) {
						background-color: transparent;
					}

					&.ant-picker-disabled {
						background-color: $themeContainerBackgroundColor;
					}
				}

				.ant-upload {
					color: $themePrimaryTextColor;
				}

				.ant-input-group-wrapper {
					.ant-input-wrapper {
						&.ant-input-group {
							.ant-input-group-addon {
								background-color: transparent;
								color: $themePrimaryTextColor;
								border-color: $themeBorderColor;

								.ant-form-item {
									margin-bottom: 0px;

									.ant-form-item-control {
										.ant-form-item-control-input {
											.ant-form-item-control-input-content {
												.ant-select {
													.ant-select-selector {
														border: none;
													}
												}
											}
										}
									}
								}
							}
						}

						.ant-input-group-addon {
							&:hover {
								border-color: $themePrimaryColor;
							}
						}
					}
				}
			}

			.ant-form-item-extra {
				color: $themePrimaryTextColor;
			}
		}

		&.ant-form-item-has-error,
		&.ant-form-item-has-error-leave {
			.ant-input,
			.ant-input-affix-wrapper,
			.ant-input-affix-wrapper:hover,
			.ant-input-suffix-wrapper,
			.ant-input-suffix-wrapper:hover,
			.ant-input:hover {
				background-color: transparent;
			}

			.ant-form-item-control {
				.ant-form-item-control-input {
					.ant-form-item-control-input-content {
						.ant-input-affix-wrapper,
						.ant-input-suffix-wrapper,
						.ant-picker {
							border-color: $themeRedColor;
						}
					}
				}
			}
		}

		&:not(.ant-form-item-has-error),
		&:not(.ant-form-item-has-error-leave) {
			.ant-form-item-control {
				.ant-form-item-control-input {
					.ant-form-item-control-input-content {
						.ant-input-affix-wrapper {
							&:not(.ant-input-affix-wrapper-focused) {
								border-color: $themeBorderColor;
							}
						}

						.ant-input-suffix-wrapper {
							&:not(.ant-input-suffix-wrapper-focused) {
								border-color: $themeBorderColor;
							}
						}

						.ant-input-affix-wrapper,
						.ant-input-suffix-wrapper {
							&:hover {
								border-color: $themePrimaryColor;
							}
						}
					}
				}
			}
		}

		.ant-upload {
			color: $themePrimaryTextColor;
		}
	}
}

.ant-input-affix-wrapper {
	color: $themePrimaryTextColor;
	background-color: $themeWhiteColor;
	border-color: $themeBorderColor;

	&.ant-input-affix-wrapper-focused,
	&:hover,
	&:focus {
		border-color: $themePrimaryColor;
	}

	.ant-input-suffix {
		.ant-input-clear-icon,
		.ant-input-search-icon {
			color: $themePrimaryTextColor;
		}
	}
}

.ant-input {
	color: $themePrimaryTextColor;
	caret-color: $themePrimaryTextColor;
	border-color: $themeBorderColor;

	&:focus,
	&.ant-input-focused {
		border-color: $themePrimaryColor;
		box-shadow: 0 0 0 2px transparentize($themePrimaryColor, 0.8);
	}

	&:disabled,
	&.ant-input-disabled {
		color: $themePrimaryTextColor;
		background-color: $themeContainerBackgroundColor;
	}

	&:not(.ant-input-disabled) {
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}
}

.ant-input-number {
	color: $themePrimaryTextColor;
	background-color: transparent;

	&:not(:hover),
	&:not(.ant-input-number-focused) {
		border-color: $themeBorderColor;
	}

	&:hover,
	&.ant-input-number-focused {
		border-color: $themePrimaryColor;
	}

	.ant-input-number-handler-wrap {
		background: $themeWhiteColor;
		border-color: $themeBorderColor;

		.ant-input-number-handler {
			color: $themePrimaryTextColor;

			.ant-input-number-handler-up,
			.ant-input-number-handler-down {
				border-color: $themeBorderColor;
			}

			.ant-input-number-handler-down-inner,
			.ant-input-number-handler-up-inner {
				color: $themePrimaryTextColor;
			}

			&:hover {
				.ant-input-number-handler-down-inner,
				.ant-input-number-handler-up-inner {
					color: $themePrimaryColor;
				}
			}
		}
	}
}

.ant-input-textarea-clear-icon {
	color: $themePrimaryTextColor;
	opacity: 0.65;

	&:hover {
		color: $themePrimaryTextColor;
		opacity: 0.85;
	}
}

.ant-picker {
	color: $themePrimaryTextColor;
	background-color: transparent;

	&:not(:hover),
	&:not(.ant-picker-focused) {
		border-color: $themeBorderColor;
	}

	&:hover,
	&.ant-picker-focused {
		border-color: $themePrimaryColor;

		.ant-picker-input {
			.ant-picker-suffix {
				color: $themePrimaryColor;
			}
		}
	}

	.ant-picker-input {
		input {
			color: $themePrimaryTextColor;
			background-color: transparent;
		}

		.ant-picker-suffix {
			color: $themePrimaryTextColor;
		}

		.ant-picker-clear {
			transition: none;
		}

		&:hover {
			.ant-picker-clear {
				background: $themeContainerBackgroundColor;
				color: $themePrimaryTextColor;
			}
		}
	}
}

.ant-picker-dropdown {
	color: $themePrimaryTextColor;

	.ant-picker-panel-container {
		background: $themeWhiteColor;

		.ant-picker-panel {
			border-color: $themeBorderColor;
			border-width: 1px;

			.ant-picker-datetime-panel,
			.ant-picker-date-panel,
			.ant-picker-time-panel,
			.ant-picker-month-panel,
			.ant-picker-year-panel,
			.ant-picker-decade-panel,
			.ant-picker-century-panel {
				.ant-picker-header {
					color: $themePrimaryTextColor;
					border-color: $themeBorderColor;

					button {
						color: $themePrimaryTextColor;

						&:hover {
							color: $themePrimaryColor;
						}
					}
				}

				.ant-picker-body {
					table {
						&.ant-picker-content {
							thead {
								tr {
									th {
										color: $themePrimaryTextColor;
									}
								}
							}

							tbody {
								tr {
									td {
										&.ant-picker-cell {
											color: $themePrimaryTextColor;

											&:hover {
												&:not(.ant-picker-cell-selected) {
													.ant-picker-cell-inner {
														background: $themePrimaryColor;
														color: $themeFixedWhiteColor;
													}
												}
											}

											&.ant-picker-cell-disabled {
												pointer-events: initial;

												&:before {
													background: $themeContainerBackgroundColor;
												}

												.ant-picker-cell-inner {
													background: $themeContainerBackgroundColor;
													color: $themePrimaryTextColor;

													&:before {
														border-color: $themePrimaryColor;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.ant-picker-footer {
					border-color: $themeBorderColor;
				}
			}

			.ant-picker-time-panel {
				.ant-picker-content {
					.ant-picker-time-panel-column {
						.ant-picker-time-panel-cell {
							&:focus {
								outline: none;
							}

							.ant-picker-time-panel-cell-inner {
								color: $themePrimaryTextColor;

								&:hover {
									background: $themeContainerBackgroundColor;
								}

								&:focus {
									outline: none;
								}
							}

							&.ant-picker-time-panel-cell-disabled {
								.ant-picker-time-panel-cell-inner {
									background: $themeContainerBackgroundColor;
								}
							}

							&.ant-picker-time-panel-cell-selected {
								.ant-picker-time-panel-cell-inner {
									background: $themeContainerBackgroundColor;
								}
							}
						}
					}
				}
			}
		}
	}
}

.ant-select {
	color: $themePrimaryTextColor;

	.ant-select-selector {
		.ant-select-selection-item {
			color: $themePrimaryTextColor;
		}

		.ant-select-selection-placeholder {
			color: $themePrimaryTextColor;
			opacity: 1;
		}

		&:not(.ant-select-disabled) {
			.ant-select-selector {
				border-color: $themeBorderColor;
			}
		}
	}

	.ant-select-arrow {
		color: $themePrimaryTextColor;
		top: 50%;
	}

	.ant-select-clear {
		background-color: $themeWhiteColor;
		color: $themePrimaryTextColor;
	}

	&.ant-select-open {
		.ant-select-selector {
			.ant-select-selection-item {
				opacity: 1;
			}
		}
	}

	&.ant-select-disabled {
		&.ant-select-single {
			&:not(.ant-select-customize-input) {
				.ant-select-selector {
					color: $themePrimaryTextColor;
					background-color: $themeContainerBackgroundColor;
				}
			}
		}
	}

	&.ant-select-lg {
		.ant-select-arrow,
		.ant-select-clear {
			top: 53%;
		}
	}

	&:not(.ant-select-disabled) {
		.ant-select-selector {
			background-color: transparent;
		}
	}

	&:hover {
		border-color: $themePrimaryColor;
	}

	&:focus,
	&.ant-select-focused {
		&:not(.ant-select-disabled) {
			.ant-select-selector {
				box-shadow: none;
				outline: none;
			}
		}
	}
}

.ant-radio-group {
	color: $themePrimaryTextColor;

	.ant-radio-wrapper {
		color: $themePrimaryTextColor;

		.ant-radio {
			color: $themePrimaryTextColor;

			.ant-radio-inner {
				background: transparent;
				border-color: $themeBorderColor;
			}

			&.ant-radio-disabled {
				color: $themePrimaryTextColor;

				&+span {
					color: $themePrimaryTextColor;
				}

				&.ant-radio-checked {
					.ant-radio-inner {
						border-color: $themePrimaryColor !important;

						&:after {
							background-color: $themePrimaryColor;
						}
					}
				}
			}

			&:not(.ant-radio-disabled) {
				color: $themePrimaryTextColor;

				&+span {
					color: $themePrimaryTextColor;
				}
			}
		}

		&.ant-radio-wrapper-checked {
			.ant-radio {
				&.ant-radio-checked {
					.ant-radio-inner {
						border-color: $themePrimaryColor;
					}
				}
			}
		}

		&:hover {
			.ant-radio {
				.ant-radio-inner {
					border-color: $themePrimaryColor;
				}

				&.ant-radio-disabled {
					.ant-radio-inner {
						border-color: $themePrimaryColor !important;
					}
				}
			}
		}
	}
}

.ant-select-dropdown {
	color: $themePrimaryTextColor;

	.ant-select-item {
		color: $themePrimaryTextColor;
	}

	&.ant-select-dropdown-empty {
		.ant-select-item-empty {
			color: $themePrimaryTextColor;

			.ant-empty {
				color: $themePrimaryTextColor;
			}
		}
	}
}

.ant-card {
	background: $themeContainerBackgroundColor;

	.ant-card-head {
		color: $themePrimaryTextColor;
	}

	.ant-card-body {
		background: $themeWhiteColor;
		color: $themePrimaryTextColor;

		.ant-card-meta {
			.ant-card-meta-avatar {
				.ant-avatar {
					background: transparent;
					color: $themePrimaryTextColor;
				}
			}

			.ant-card-meta-detail {
				.ant-card-meta-title {
					color: $themeSecondaryTextColor;
				}

				.ant-card-meta-description {
					color: $themePrimaryTextColor;
				}
			}
		}
	}

	.ant-card-actions {
		background: $themeWhiteColor;
		border-color: $themeBorderColor;

		li {
			color: $themePrimaryTextColor;
			border-color: $themeBorderColor;

			span {
				.anticon {
					color: $themePrimaryTextColor;
				}
			}
		}
	}

	&.ant-card-bordered {
		border-color: $themeBorderColor;
	}
}

.ant-switch {
	color: $themePrimaryTextColor;

	&:after {
		background-color: $themeWhiteColor;
	}

	&.ant-switch-checked {
		background-color: $themePrimaryColor;
	}
}

.ant-upload-picture-card-wrapper {
	.ant-upload-list {
		&.ant-upload-list-picture-card {
			color: $themePrimaryTextColor;
			user-select: none;

			.ant-upload-list-picture-card-container {
				.ant-upload-list-item {
					border-color: $themeBorderColor;

					&.ant-upload-list-item-error {
						&.ant-upload-list-item-list-type-picture-card {
							border-color: $themeRedColor;
						}
					}

					&.ant-upload-list-item-done {
						&.ant-upload-list-item-list-type-picture-card {
							border-color: $themeBorderColor;
						}
					}

					&.ant-upload-list-item-uploading {
						&.ant-upload-list-item-list-type-picture-card {
							background-color: $themeContainerBackgroundColor;

							.ant-upload-list-item-progress {
								.ant-progress {
									.ant-progress-line {
										color: $themePrimaryTextColor;

										.ant-progress-outer {
											.ant-progress-inner {
												background-color: $themeBorderColor;

												.ant-progress-bg {
													background-color: $themePrimaryColor;
												}
											}
										}
									}
								}
							}
						}
					}

					&.ant-upload-list-item-list-type-picture-card {
						.ant-upload-list-item-actions {
							.ant-upload-list-item-card-actions-btn {
								&.ant-btn {
									border: none;
									background-color: transparent;
								}
							}
						}
					}
				}
			}
		}
	}

	.ant-upload {
		color: $themePrimaryTextColor;

		&.ant-upload-select {
			&.ant-upload-select-picture-card {
				border-color: $themeBorderColor;
				background-color: $themeWhiteColor;

				.ant-upload {
					color: $themePrimaryTextColor;
				}
			}
		}
	}
}

.ant-table {
	color: $themePrimaryTextColor;
	background-color: $themeWhiteColor;

	&.ant-table-bordered {
		.ant-table-container {
			border-color: $themeBorderColor;

			.ant-table-content {
				.ant-table-tbody {
					tr {
						th,
						td {
							border-left-color: $themeBorderColor;
							border-right-color: $themeBorderColor;
							border-top-color: $themeBorderColor;
							border-bottom-color: $themeBorderColor;
						}
					}
				}
			}
		}
	}

	.ant-table-content {
		.ant-table-thead {
			tr {
				th,
				td {
					color: $themeSecondaryTextColor;
					border-color: $themeBorderColor;
					background: $themeContainerBackgroundColor;

					.ant-table-filter-column {
						.ant-table-filter-trigger-container {

							&.ant-table-filter-trigger-container-open,
							&:hover {
								background: $themeWhiteColor;

								.ant-table-filter-trigger {
									color: $themeSecondaryTextColor;
								}
							}

							.ant-table-filter-trigger {
								color: $themePrimaryTextColor;

								&:hover {
									color: $themeSecondaryTextColor;
								}
							}
						}
					}
				}
			}
		}

		.ant-table-tbody {
			tr {
				th,
				td {
					border-left-color: $themeBorderColor;
					border-right-color: $themeBorderColor;
					border-top-color: $themeBorderColor;
					border-bottom-color: $themeBorderColor;
					background: $themeWhiteColor;

					.ant-table-row-expand-icon {
						border-color: $themePrimaryColor;
						color: $themePrimaryColor;
						background: $themeWhiteColor;
					}
				}

				&.ant-table-expanded-row {
					td {
						padding: 8px 16px;

						&:first-child {
							padding-left: 65px;
						}
					}
				}

				&:not(.ant-table-placeholder) {
					&:hover {
						td {
							background: $themeContainerBackgroundColor;

							.ant-table-row-expand-icon {
								background: $themeContainerBackgroundColor;
							}
						}
					}
				}
			}

			.ant-table-placeholder {
				color: $themeSecondaryTextColor;

				.ant-empty {
					color: $themeSecondaryTextColor;
				}
			}
		}
	}
}

.ant-empty {
	color: $themeSecondaryTextColor;
}

.ant-popover {
	color: $themePrimaryTextColor;

	.ant-popover-content {
		.ant-popover-arrow {
			border-color: transparent $themeBorderColor $themeBorderColor transparent;
		}

		.ant-popover-inner {
			background-color: $themeWhiteColor;

			.ant-popover-title {
				color: $themeSecondaryTextColor;
				border-color: $themeBorderColor;
			}

			.ant-popover-inner-content {
				color: $themePrimaryTextColor;
			}			
		}
	}
}

.ant-divider {
	color: $themeBorderColor;
	border-color: $themeBorderColor;
	
	&.ant-divider-with-text {
		color: $themeBorderColor;
		border-color: $themeBorderColor;
	}
}

.ant-tag {
	color: $themePrimaryTextColor;
	border-color: $themePrimaryTextColor;
	background-color: $themeContainerBackgroundColor;

	a {
		color: $themePrimaryTextColor;

		&:hover {
			color: $themePrimaryTextColor;
		}
	}

	&:hover {
		opacity: 1;
	}
}

.ant-checkbox-wrapper {
	color: $themePrimaryTextColor;

	&.ant-checkbox-wrapper-disabled {
		.ant-checkbox {
			&.ant-checkbox-disabled {
				&+span {
					color: $themePrimaryTextColor;
				}
			}
		}
	}
}

@media (prefers-color-scheme: dark) {
	.ant-popover {
		.ant-popover-content {
			.ant-popover-inner {
				border: 1px solid $themeBorderColor;
			}
		}
	}

	.ant-skeleton {
		&.ant-skeleton-active {
			.ant-skeleton-avatar,
			.ant-skeleton-button,
			.ant-skeleton-input {
				background: linear-gradient(90deg,rgba(0,0,0,0.8) 25%,rgba(255,255,255,0.16) 37%,rgba(0,0,0,0.8) 63%);
				background-size: 400% 100%;
			}

			.ant-skeleton-content {
				.ant-skeleton-paragraph > li,
				.ant-skeleton-title {
					background: linear-gradient(90deg,rgba(0,0,0,0.8) 25%,rgba(255,255,255,0.16) 37%,rgba(0,0,0,0.8) 63%);
					background-size: 400% 100%;
				}
			}
		}
	}

	.ant-select-dropdown {
		background-color: $themeWhiteColor;

		.ant-select-item {
			&.ant-select-item-option-active {
				&:not(.ant-select-item-option-disabled) {
					background-color: rgba(255, 255, 255, 0.08);
				}
			}
			&.ant-select-item-option-selected {
				&:not(.ant-select-item-option-disabled) {
					background-color: rgba(255, 255, 255, 0.08);
				}

				&.ant-select-item-option-disabled {
					background-color: $themeWhiteColor;
				}
			}
		}
	}

	.ant-checkbox-wrapper {
		color: $themePrimaryTextColor;

		.ant-checkbox {
			color: $themePrimaryTextColor;

			.ant-checkbox-inner {
				background-color: transparent;
				border-color: $themeBorderColor;

				&:after {
					border-color: $themePrimaryColor;
				}
			}

			&.ant-checkbox-checked {
				.ant-checkbox-inner {
					background-color: $themePrimaryColor;
					border-color: $themePrimaryColor;

					&:after {
						border-color: $themeBlackColor;
					}
				}
			}
		}
	}

	.ant-switch {
		color: $themeBlackColor;
		background-color: rgba(255,255,255,0.3);

		&:after {
			background-color: $themeBlackColor;
		}

		&.ant-switch-checked {
			background-color: $themePrimaryColor;
		}
	}

	.ant-tabs-dropdown {
		&:not(.ant-tabs-dropdown-hidden) {
			border: 1px solid $themeBorderColor;
		}
	}
}

@media screen and (max-width: 575px) {
	.ant-drawer {
		&.ant-drawer-open {
			.ant-drawer-content-wrapper {
				min-width: 100%;
			}
		}
	}
}

@media screen and (min-width: 576px) and (max-width: 991px) {
	.ant-drawer {
		&.ant-drawer-open {
			.ant-drawer-content-wrapper {
				min-width: 75%;
			}
		}
	}
}

@media screen and (min-width: 992px) {
	.ant-drawer {
		&.ant-drawer-open {
			.ant-drawer-content-wrapper {
				min-width: 50%;
			}
		}
	}
}

.cookie-consent-container {
	align-items: baseline;
	background: $themeWhiteColor;
	border-top: 1px solid $themeBorderColor;
	color: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	left: 0px;
	position: fixed;
	width: 100%;
	z-index: 999;
	bottom: 0px;
	padding: 18px 36px;

	.cookie-consent-content-container {
		width: 70%;
		color: $themeSecondaryTextColor;
	}

	.cookie-consent-button-container {
		width: 25%;
		text-align: right;

		.ant-btn {
			box-shadow: none;
			word-spacing: 6px;
		}
	}
}
