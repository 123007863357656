@import "../../Config";

.login-page-container,
.signup-page-container,
.forgot-password-page-container {
	height: 100%;
	width: 100%;
	margin: 0px auto;
	padding: 0px;

	.login-banner-container,
	.signup-banner-container,
	.forgot-password-banner-container {
		.login-banner-title-container,
		.signup-banner-title-container,
		.forgot-password-banner-title-container {
			height: 100%;
			width: 100%;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: #391085;
			padding: 0px 10%;

			.login-banner,
			.signup-banner,
			.forgot-password-banner {
				position: absolute;
				top: 0px;
				left: 0px;
				height: 100%;
				width: 100%;
				background-size: 35%;
				background-repeat: no-repeat;
				background-position: calc(100% - 48px) calc(100% - 48px);
				z-index: 1;
			}

			.login-banner,
			.signup-banner,
			.forgot-password-banner {
				background-image: url('https://schedulify.app/images/login/Banner.webp'), url('https://schedulify.app/images/login/Banner.png');
			}

			.left-shape,
			.right-shape {
				position: absolute;
				top: 0px;
				height: 100%;
				width: 100%;
				margin: 0px;
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 0;
			}

			.left-shape {
				left: 0px;
				background-image: url('https://schedulify.app/images/website/ShapeLeftPattern.webp'), url('https://schedulify.app/images/website/ShapeLeftPattern.png');
				background-position: top 50%;
			}

			.right-shape {
				right: 0px;
				background-image: url('https://schedulify.app/images/website/ShapeRightPattern.webp'), url('https://schedulify.app/images/website/ShapeRightPattern.png');
				background-position: right 80px;
			}

			.login-banner-title,
			.signup-banner-title,
			.forgot-password-banner-title {
				font-size: 56px;
				font-weight: 500;
				margin-bottom: 24px;
				color: $themeFixedWhiteColor;
				z-index: 1;

				a {
					color: $themeFixedWhiteColor;
				}
			}

			.login-banner-title-divider,
			.signup-banner-title-divider,
			.forgot-password-banner-title-divider {
				&.ant-divider {
					margin: 0px 0px 32px 0px;
					border: 0.05rem solid $themeFixedWhiteColor;
					color: $themeFixedWhiteColor;
					min-width: 50px;
					width: 50px;
				}
			}

			.login-banner-subtitle,
			.signup-banner-subtitle,
			.forgot-password-banner-subtitle {
				font-size: 22px;
				font-weight: 500;
				margin-bottom: 18px;
				color: $themeFixedWhiteColor;
				max-width: 400px;
				z-index: 1;
			}

			.login-banner-description,
			.signup-banner-description,
			.forgot-password-banner-description {
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 0px;
				color: $themeFixedWhiteColor;
				max-width: 400px;
				z-index: 1;
			}
		}
	}

	.login-form-container,
	.signup-form-container,
	.forgot-password-form-container {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: $themeWhiteColor;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
		padding: 36px;

		.login-form-logo,
		.signup-form-logo,
		.forgot-password-form-logo {
			color: $themeSecondaryTextColor;
			display: flex;
			align-items: center;
			position: absolute;
			top: 18px;
			left: 18px;

			.logo {
				height: 64px;
				width: 64px;
			}

			.text {
				font-size: 24px;
				margin: 0px;
				font-weight: 400;
				color: $themeSecondaryTextColor;
			}
		}

		.login-form-icon,
		.signup-form-icon,
		.forgot-password-form-icon {
			border-radius: 50%;
			background: $themePrimaryColor;
			height: 56px;
			width: 56px;
			margin: 0px auto 24px auto;

			.anticon {
				height: 56px;
				width: 56px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $themeFixedWhiteColor;

				svg {
					height: 24px;
					width: 24px;
				}
			}
		}

		.login-form-header,
		.signup-form-header,
		.forgot-password-form-header {
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			color: $themeSecondaryTextColor;
		}

		.login-form,
		.signup-form,
		.forgot-password-form {
			&.ant-form {
				.ant-form-item {
					width: 100%;
					margin: 0px auto 6px auto;

					.ant-input {
						&[type="email"] {
							text-transform: lowercase;

							&::placeholder {
								text-transform: none;
							}
						}
					}

					.ant-btn {
						width: 100%;
					}

					.ant-input-affix-wrapper:not(:first-child) {
						padding: 5px 11px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		.login-form-container,
		.signup-form-container,
		.forgot-password-form-container {
			.login-form,
			.signup-form,
			.forgot-password-form {
				&.ant-form {
					box-shadow: none;
					background: $themeWhiteColor;
				}
			}
		}
	}
}
