@import "../../../Config";

.static-website-about-page-container {
	height: 100%;
	width: 100%;
	padding: 24px 36px;

	.about-page-content {
		font-size: 18px;
		color: $themePrimaryTextColor;
		max-width: 1199px;
		margin: 0px auto;

		.about-page-title {
			font-size: 42px;
			color: $themeSecondaryTextColor;
			margin-top: 0px;
			margin-bottom: 24px;
			text-align: center;
		}
	}
}
