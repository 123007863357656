@import "../../../../../Config";

.pricing-table {
	&.ant-table-wrapper {
		width: 100%;
		margin-bottom: 42px;

		.ant-table {
			.ant-table-container {
				.ant-table-content {
					.ant-table-tbody {
						tr {
							td,
							th {
								font-size: 16px;
								color: $themePrimaryTextColor;

								.pricing-plan-name-container {
									text-align: center;

									.pricing-plan-name {
										color: $themeSecondaryTextColor;
										font-size: 24px;
									}

									.pricing-plan-value {
										.pricing-plan-amount {
											font-size: 24px;
											color: $themeSecondaryTextColor;
										}

										.pricing-plan-unit {
											font-size: 14px;
										}
									}

									.pricing-plan-icon {
										margin-top: 18px;
										margin-bottom: 12px;

										img {
											width: 36px;
											height: 36px;
										}
									}
								}

								.pricing-plan-feature-container {
									.anticon {
										&.info-icon {
											margin-left: 12px;

											&:hover {
												color: $themeSecondaryTextColor;
												cursor: pointer;
											}
										}

										&.check-icon {
											color: $themeGreenColor;
										}
									}
								}
							}

							&:hover {
								td,
								th {
									background-color: $themeWhiteColor;
								}
							}

							&:first-child {
								td,
								th {
									background-color: $themeContainerBackgroundColor;
								}
							}
						}
					}
				}
			}
		}
	}
}