@import "../../../Config";

.static-website-pricing-page-container {
	height: 100%;
	width: 100%;
	padding: 24px 36px;

	.pricing-page-title {
		font-size: 42px;
		color: $themeSecondaryTextColor;
		margin-bottom: 8px;
		text-align: center;
		font-weight: 500;
	}

	.pricing-page-subtitle {
		font-size: 18px;
		color: $themePrimaryTextColor;
		margin-bottom: 36px;
		text-align: center;
		font-weight: 500;
	}

	.pricing-page-content {
		font-size: 18px;
		color: $themePrimaryTextColor;
		max-width: 700px;
		margin: 0px auto 36px auto;
	}

	.pricing-table-container {
		max-width: 991px;
		margin: 0px auto;

		.pricing-radio-container {
			text-align: center;
			margin-bottom: 48px;
			position: relative;

			.save-yearly {
				position: absolute;
				left: calc(50% - 195px);
				top: 6px;
				display: none;
			}

			@media screen and (min-width: 576px) {
				.save-yearly {
					display: block;
				}
			}

			.ant-radio-group {
				.ant-radio-button-wrapper {
					height: 42px;
					line-height: 40px;
					font-size: 18px;
					color: $themeFixedBlackColor;
					border-color: $themeBorderColor;

					&.ant-radio-button-wrapper-checked {
						color: $themeFixedWhiteColor;
						background-color: $themePrimaryColor;
						border-color: $themePrimaryColor;

						&:not(.ant-radio-button-wrapper-disabled) {
							z-index: 0;
						}
					}

					&:first-child {
						border-radius: 12px 0 0 12px;
					}

					&:last-child {
						border-radius: 0px 12px 12px 0px;
					}
				}
			}
		}

		.pricing-card-container {
			.pricing-card-column {
				.pricing-card {
					&.ant-card {
						box-shadow: 0px 4px 20px rgba(1, 1, 1, 0.5);
						border-radius: 8px;

						.ant-card-body {
							padding: 0px;
							border-radius: 8px;
							height: 330px;
							background-color: $themeContainerBackgroundColor;
							border: 1px solid $themeBorderColor;

							.ant-card-meta {
								height: 100%;
								margin: 0px;

								.ant-card-meta-detail {
									height: 100%;

									.ant-card-meta-title {
										padding: 0px 24px 0px 24px;
										background-color: $themeWhiteColor;
										margin-bottom: -24px;
										border-top-left-radius: 8px;
										border-top-right-radius: 8px;

										.pricing-card-title {
											text-align: center;
											color: $themeSecondaryTextColor;
											font-size: 24px;
											margin-top: 18px;
										}

										.pricing-card-icon {
											text-align: center;
											margin-bottom: 6px;
											margin-top: 12px;

											img {
												height: 42px;
												width: 42px;
											}
										}

										.pricing-card-price {
											text-align: center;
											margin-bottom: 1px;

											.pricing-card-amount {
												color: $themeSecondaryTextColor;
												font-size: 24px;
											}

											.pricing-card-unit {
												color: $themePrimaryTextColor;
											}
										}

										.ant-divider {
											margin: 24px 0px 0px 0px;
											margin-left: -36px;
											min-width: calc(100% + 72px);
											width: calc(100% + 72px);
										}
									}

									.ant-card-meta-description {
										padding: 0px 36px 0px 36px;
										color: $themePrimaryTextColor;
										font-size: 16px;

										.anticon {
											&.check-icon {
												color: $themeGreenColor;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.actions-row {
			.actions-column {
				position: relative;

				.action-title-container {
					.action-title {
						font-size: 36px;
						margin-bottom: 36px;
						color: $themeSecondaryTextColor;
					}

					.action-subtitle {
						font-size: 18px;
						margin-bottom: 48px;
						color: $themePrimaryTextColor;
					}
				}

				.actions-divider {
					position: absolute;
					right: 12px;
					top: 42px;
					min-height: 230px;
					color: $themeBorderColor;
					border-left: 0.05rem solid $themeBorderColor;
					margin: 0px;
				}


				@media screen and (max-width: 767px) {
					.actions-divider {
						display: none;
					}
				}
			}
		}
	}
}
