@import "../../Config";

.static-website-home-page-container {
	height: 100%;
	width: 100%;

	.section-divider {
		&.ant-divider {
			margin: 0px;
			color: $themeSecondaryTextColor;
			border-color: $themeBorderColor;
		}
	}

	.website-section {
		height: fit-content;
		min-height: calc(100vh - 64px);
		width: 100%;
		position: relative;

		.left-shape,
		.right-shape {
			position: absolute;
			top: 24px;
			height: calc(100% - 48px);
			width: 100%;
			margin: 0px;
			background-repeat: no-repeat;
			background-size: contain;
		}

		.left-shape {
			left: 0px;
			background-image: url('https://schedulify.app/images/website/ShapeLeftPattern.webp'), url('https://schedulify.app/images/website/ShapeLeftPattern.png');
			background-position: top left;
		}

		.right-shape {
			right: 0px;
			background-image: url('https://schedulify.app/images/website/ShapeRightPattern.webp'), url('https://schedulify.app/images/website/ShapeRightPattern.png');
			background-position: top right;
		}

		@media screen and (max-width: 767px) {
			.left-shape {
				display: none;
			}

			.right-shape {
				display: none;
			}
		}

		&.website-section-banner-container {
			padding: 0px 36px;
			background-color: $themeContainerBackgroundColor;

			.website-section-banner-overlay {
				position: absolute;
				top: 0px;
				left: -1px;
				height: 100%;
				width: 100%;
				background-image: url('https://schedulify.app/images/website/WebsiteBannerOverlay.webp'), url('https://schedulify.app/images/website/WebsiteBannerOverlay.png');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: top right;
			}

			.animated-icons {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				height: 100%;
				width: 100%;
				max-width: 1920px;
				margin: 0px auto;

				.icon {
					position: absolute;
					background-position: center;
					background-repeat: no-repeat;

					img {
						display: inline-block;
						max-width: 100%;
						height: auto;
						transition-delay: .1s;
						transition-timing-function: ease-in-out;
						transition-duration: .7s;
						transition-property: all;
					}

					&:nth-child(1) {
						left: 95px;
						top: 100px;
						animation: zoom-fade 2s infinite linear;
					}

					&:nth-child(2) {
						left: 100px;
						bottom: 50px;
						animation: rotate 30s infinite linear;
					}

					&:nth-child(3) {
						right: 100px;
						top: 200px;
						animation: rotate 30s infinite linear;
					}

					&:nth-child(4) {
						right: 650px;
						bottom: 100px;
						animation: rotate 30s infinite linear;
					}

					&:nth-child(5) {
						right: 90px;
						top: 40px;
						animation: rotate 30s infinite linear;
					}
				}
			}

			@media screen and (max-width: 767px) {
				.animated-icons {
					display: none;
				}
			}

			.website-section-banner {
				padding: 0px;
				min-height: calc(100vh - 64px);
				height: calc(100vh - 64px);

				.website-section-banner-column {
					.website-section-banner-title-container {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						.website-section-banner-title {
							font-size: 48px;
							font-weight: 500;
							margin: 0px 0px 12px 0px;
							color: $themeFixedWhiteColor;
						}

						.website-section-banner-title-divider {
							&.ant-divider {
								margin: 0px 0px 24px 0px;
								color: $themeFixedWhiteColor;
								border: 0.05rem solid $themeFixedWhiteColor;
								min-width: 50px;
								width: 50px;
							}
						}

						.website-section-banner-subtitle {
							font-size: 24px;
							font-weight: 500;
							color: $themeFixedWhiteColor;
							margin: 0px 0px 16px 0px;
						}

						.website-section-banner-description {
							font-size: 18px;
							font-weight: 500;
							color: $themeFixedWhiteColor;
							margin: 0px;
						}
					}

					.website-section-banner-image-container {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.website-section-banner-image {
							max-height: 500px;
						}
					}

					&:nth-child(1) {
						height: 45%;
					}

					&:nth-child(2) {
						height: 55%;
					}

					@media screen and (min-width: 768px) {
						.website-section-banner-image-container {
							align-items: flex-start;
						}
					}
				}
			}
		}

		&.website-section-learn-container {
			padding: 0px 12px;

			.website-section-learn {
				padding: 0px 0px 24px 0px;
				min-height: calc(100vh - 64px);
				background-image: url('https://schedulify.app/images/website/LearnMoreBanner.svg');
				background-repeat: no-repeat;
				background-position: center calc(100% - 12px);
				background-size: contain;

				.website-section-learn-column {
					.website-section-learn-title-container {
						text-align: center;

						.website-section-learn-title {
							font-size: 42px;
							font-weight: 500;
							margin-bottom: 12px;
							color: $themeSecondaryTextColor;
						}

						.website-section-learn-subtitle {
							font-size: 20px;
							font-weight: 500;
							margin-bottom: 36px;
							color: $themePrimaryTextColor;
						}
					}
				}
			}
		}

		&.website-section-pricing-container {
			padding: 0px 36px;
			background-color: $themeContainerBackgroundColor;

			.website-section-pricing-overlay {
				position: absolute;
				top: 0px;
				left: 0px;
				height: 100%;
				width: 100%;
				background-image: url('https://schedulify.app/images/website/PricingOverlay.webp'), url('https://schedulify.app/images/website/PricingOverlay.png');
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center calc(100% + 260px);
			}

			.animated-icons {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				height: 100%;
				width: 100%;
				max-width: 1920px;
				margin: 0px auto;

				.icon {
					position: absolute;
					background-position: center;
					background-repeat: no-repeat;

					img {
						display: inline-block;
						max-width: 100%;
						height: auto;
						transition-delay: .1s;
						transition-timing-function: ease-in-out;
						transition-duration: .7s;
						transition-property: all;
					}

					&:nth-child(1) {
						left: 95px;
						top: 40px;
						animation: zoom-fade 2s infinite linear;
					}

					&:nth-child(2) {
						left: 100px;
						bottom: 30px;
						animation: rotate 30s infinite linear;
					}

					&:nth-child(3) {
						right: 100px;
						bottom: 70px;
						animation: rotate 30s infinite linear;
					}

					&:nth-child(4) {
						right: 650px;
						bottom: 20px;
						animation: rotate 30s infinite linear;
					}

					&:nth-child(5) {
						right: 90px;
						top: 100px;
						animation: rotate 30s infinite linear;
					}
				}
			}

			.website-section-pricing {
				padding: 24px 0px 0px 0px;
				min-height: calc(100vh - 64px);
				max-width: 991px;
				margin: 0px auto;

				.website-section-pricing-title-container {
					text-align: center;

					.website-section-pricing-title {
						font-size: 42px;
						font-weight: 500;
						color: $themeSecondaryTextColor;
						margin-bottom: 0px;
					}
				}

				.website-section-pricing-card-container {
					.website-section-pricing-card-row {
						.website-section-pricing-card-column {
							.website-section-pricing-radio-container {
								text-align: center;
								margin-bottom: 24px;
								position: relative;

								.save-yearly {
									position: absolute;
									left: calc(50% - 195px);
									top: 6px;
									display: none;
								}

								@media screen and (min-width: 576px) {
									.save-yearly {
										display: block;
									}
								}

								.ant-radio-group {
									.ant-radio-button-wrapper {
										height: 42px;
										line-height: 40px;
										font-size: 18px;
										color: $themeFixedBlackColor;
										border-color: $themeBorderColor;

										&.ant-radio-button-wrapper-checked {
											color: $themeFixedWhiteColor;
											background-color: $themePrimaryColor;
											border-color: $themePrimaryColor;

											&:not(.ant-radio-button-wrapper-disabled) {
												z-index: 0;
											}
										}

										&:first-child {
											border-radius: 12px 0 0 12px;
										}

										&:last-child {
											border-radius: 0px 12px 12px 0px;
										}
									}
								}
							}

							.website-section-pricing-card {
								&.ant-card {
									box-shadow: 0px 4px 20px rgba(1, 1, 1, 0.5);
									border-radius: 8px;

									.ant-card-body {
										padding: 0px;
										border-radius: 8px;
										height: 330px;
										background-color: $themeContainerBackgroundColor;
										border: 1px solid $themeBorderColor;

										.ant-card-meta {
											height: 100%;
											margin: 0px;

											.ant-card-meta-detail {
												height: 100%;

												.ant-card-meta-title {
													padding: 0px 24px 0px 24px;
													background-color: $themeWhiteColor;
													margin-bottom: -24px;
													border-top-left-radius: 8px;
													border-top-right-radius: 8px;

													.website-section-pricing-card-title {
														text-align: center;
														color: $themeSecondaryTextColor;
														font-size: 24px;
														margin-top: 18px;
													}

													.website-section-pricing-card-icon {
														text-align: center;
														margin-bottom: 6px;
														margin-top: 12px;

														img {
															height: 42px;
															width: 42px;
														}
													}

													.website-section-pricing-card-price {
														text-align: center;
														margin-bottom: 1px;

														.website-section-pricing-card-amount {
															color: $themeSecondaryTextColor;
															font-size: 24px;
														}

														.website-section-pricing-card-unit {
															color: $themePrimaryTextColor;
														}
													}

													.ant-divider {
														margin: 24px 0px 0px 0px;
														margin-left: -36px;
														min-width: calc(100% + 72px);
														width: calc(100% + 72px);
													}
												}

												.ant-card-meta-description {
													padding: 0px 36px 0px 36px;
													color: $themePrimaryTextColor;
													font-size: 16px;

													.anticon {
														&.check-icon {
															color: $themeGreenColor;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.website-section-feature-container {
			padding: 12px 36px;

			&:nth-of-type(odd) {
				background-color: $themeContainerBackgroundColor;
			}

			.website-section-feature {
				padding: 24px 0px 0px 0px;
				min-height: calc(100vh - 64px);

				.website-section-feature-column {
					.website-section-feature-title-container {
						.website-section-feature-title {
							font-size: 42px;
							font-weight: 500;
							margin-bottom: 12px;
							color: $themeSecondaryTextColor;
						}

						.website-section-feature-subtitle {
							font-size: 20px;
							font-weight: 500;
							margin-bottom: 24px;
							color: $themePrimaryTextColor;
						}
					}

					.website-section-feature-image-container {
						display: flex;
						justify-content: center;
						align-items: center;

						.website-section-feature-image {
							max-height: 350px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 767px) {
			&.website-section-feature-container {
				padding: 0px 36px 24px 36px;
			}
		}

		&.website-section-feature-card-container {
			padding: 0px 36px;

			.website-section-feature-card-title-container {
				text-align: center;

				.website-section-feature-card-title {
					font-size: 42px;
					font-weight: 500;
					color: $themeSecondaryTextColor;
					margin-bottom: 0px;
				}
			}

			.website-section-feature-card {
				max-width: 1199px;
				padding: 36px 0px 24px 0px;
				margin: 0 auto;

				.website-section-feature-card-column {
					.website-section-feature-card-image-container {
						&.ant-card {
							border: 1px solid $themeBorderColor;
							box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);

							@media screen and (max-width: 767px) {
								padding: 24px 32px 32px 32px;
								min-height: 480px;
								position: relative;
								background-repeat: no-repeat;
								background-position: 50% 12px;
								background-size: contain;

								.ant-card-body {
									width: calc(100% - 64px);
									position: absolute;
									bottom: 32px;
									padding: 0px;
									background-color: transparent;

									.ant-card-meta {
										.ant-card-meta-detail {

											.ant-card-meta-title,
											.ant-card-meta-description {
												color: $themeFixedBlackColor;
											}

											.ant-card-meta-title {
												font-size: 24px;

												.ant-divider {
													color: $themePrimaryColor;
													border: 0.05rem solid $themePrimaryColor;
													min-width: 50px;
													width: 50px
												}
											}

											.ant-card-meta-description {
												font-size: 18px;
											}
										}
									}
								}
							}

							@media screen and (min-width: 768px) {
								padding: 24px 32px 0px 32px;
								max-width: 480px;
								min-height: 240px;
								background-repeat: no-repeat;
								background-position: calc(100% - 12px) 50%;
								background-size: contain;

								.ant-card-cover {
									color: $themeFixedBlackColor;
									font-size: 24px;

									.ant-divider {
										margin: 24px 0px;
										color: $themePrimaryColor;
										border: 1px solid $themePrimaryColor;
										min-width: 50px;
										width: 50px;
									}
								}

								.ant-card-body {
									padding: 0px;
									background-color: transparent;

									.ant-card-meta {
										.ant-card-meta-detail {
											.ant-card-meta-title {
												font-size: 16px;
												color: $themeFixedBlackColor;
												white-space: normal;
												max-width: 50%;

												.website-section-feature-card-image {
													height: 200px;
													width: auto;
													margin: 0px auto;
												}
											}
										}
									}
								}
							}
						}
					}

					&:nth-child(2) {
						.website-section-feature-card-image-container {
							&.ant-card {
								margin: auto 0px 0px auto;
								background-image: url('https://schedulify.app/images/website/FeatureIcon1.webp'), url('https://schedulify.app/images/website/FeatureIcon1.png');
								background-color: #FFF9EA;
								border-top-right-radius: 24px;
								border-bottom-left-radius: 24px;
							}
						}

						@media screen and (max-width: 767px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-size: 280px;
								}
							}
						}

						@media screen and (min-width: 768px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-position: calc(100% - 12px) calc(50% + 12px);
									background-size: calc(45%);
								}
							}
						}
					}

					&:nth-child(3) {
						.website-section-feature-card-image-container {
							&.ant-card {
								background-image: url('https://schedulify.app/images/website/FeatureIcon2.webp'), url('https://schedulify.app/images/website/FeatureIcon2.png');
								background-color: #F3EFFF;
								border-top-left-radius: 24px;
								border-bottom-right-radius: 24px;
							}
						}

						@media screen and (max-width: 767px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-size: 300px;
								}
							}
						}

						@media screen and (min-width: 768px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-size: calc(45%);
								}
							}
						}
					}

					&:nth-child(4) {
						.website-section-feature-card-image-container {
							&.ant-card {
								margin: auto 0px 0px auto;
								background-image: url('https://schedulify.app/images/website/FeatureIcon3.webp'), url('https://schedulify.app/images/website/FeatureIcon3.png');
								background-color: #F3F8FF;
								border-top-left-radius: 24px;
								border-bottom-right-radius: 24px;
							}
						}

						@media screen and (max-width: 767px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-position: 50% 42px;
									background-size: 200px;
								}
							}
						}

						@media screen and (min-width: 768px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-size: calc(40%);
								}
							}
						}
					}

					&:nth-child(5) {
						.website-section-feature-card-image-container {
							&.ant-card {
								background-image: url('https://schedulify.app/images/website/FeatureIcon4.webp'), url('https://schedulify.app/images/website/FeatureIcon4.png');
								background-color: #F3FFDB;
								border-top-right-radius: 24px;
								border-bottom-left-radius: 24px;
							}
						}

						@media screen and (max-width: 767px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-position: 50% 48px;
									background-size: 320px;
								}
							}
						}

						@media screen and (min-width: 768px) {
							.website-section-feature-card-image-container {
								&.ant-card {
									background-position: calc(100% + 18px) 50%;
									background-size: calc(60%);
								}
							}
						}
					}
				}
			}
		}
	}
}

@keyframes zoom-fade {
	0% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}