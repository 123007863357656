@import "../../../Config";

.static-website-features-page-container {
	height: 100%;
	width: 100%;

	.section-divider {
		&.ant-divider {
			margin: 0px;
			color: $themeSecondaryTextColor;
			border-color: $themeBorderColor;
		}
	}

	.features-page-container {
		.features-page-section {
			padding: 0px 36px;
			height: fit-content;
			min-height: calc(100vh - 64px);
			width: 100%;
			position: relative;
			
			.features-screenshot-carousel-container {
				margin: 0px auto;
				max-width: 767px;

				.features-screenshot-carousel {
					&.carousel-root {
						margin-top: 48px;
					}
				}
			}

			.left-shape,
			.right-shape {
				position: absolute;
				top: 24px;
				height: calc(100% - 48px);
				width: 100%;
				margin: 0px;
				background-repeat: no-repeat;
				background-size: contain;
			}

			.left-shape {
				left: 0px;
				background-image: url('https://schedulify.app/images/website/ShapeLeftPattern.webp'), url('https://schedulify.app/images/website/ShapeLeftPattern.png');
				background-position: top left;
			}

			.right-shape {
				right: 0px;
				background-image: url('https://schedulify.app/images/website/ShapeRightPattern.webp'), url('https://schedulify.app/images/website/ShapeRightPattern.png');
				background-position: top right;
			}

			@media screen and (max-width: 767px) {
				.left-shape {
					display: none;
				}

				.right-shape {
					display: none;
				}
			}

			&.banner-section {
				padding: 24px 36px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: top right;
				background-image: url('https://schedulify.app/images/website/WebsiteBannerOverlay.webp'), url('https://schedulify.app/images/website/WebsiteBannerOverlay.png');
				background-color: $themeContainerBackgroundColor;

				.features-page-title {
					font-size: 36px;
					font-weight: 500;
					color: $themeFixedWhiteColor;
					text-align: center;
					max-width: 800px;
					margin: 12px auto 32px auto;
				}

				.features-page-subtitle {
					font-size: 24px;
					font-weight: 500;
					color: $themeFixedWhiteColor;
					text-align: center;
					max-width: 800px;
					margin: 24px auto;
				}
			}

			&.feature-section {
				&:nth-child(5),
				&:nth-child(9),
				&:nth-child(13) {
					background-color: $themeContainerBackgroundColor;
				}

				.feature-row {
					padding: 24px 0px 24px 0px;
					min-height: calc(100vh - 64px);

					.feature-column {
						.feature-title-container {
							.feature-title {
								font-size: 42px;
								font-weight: 500;
								margin-bottom: 12px;
								color: $themeSecondaryTextColor;
							}

							.feature-subtitle {
								font-size: 20px;
								font-weight: 500;
								margin-bottom: 24px;
								color: $themePrimaryTextColor;
							}
						}

						.feature-image-container {
							display: flex;
							justify-content: center;
							align-items: center;

							.feature-image {
								max-height: 400px;
							}
						}
					}
				}
			}

			&.actions-section {
				padding: 36px;
				height: 100%;
				min-height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				max-width: 991px;
				margin: 0px auto;

				.actions-row {
					.actions-column {
						position: relative;

						.action-title-container {
							.action-title {
								font-size: 36px;
								margin-bottom: 36px;
								color: $themeSecondaryTextColor;
							}

							.action-subtitle {
								font-size: 18px;
								margin-bottom: 48px;
								color: $themePrimaryTextColor;
							}
						}

						.actions-divider {
							position: absolute;
							right: 12px;
							top: 42px;
							min-height: 230px;
							color: $themeBorderColor;
							border-left: 0.05rem solid $themeBorderColor;
						}


						@media screen and (max-width: 767px) {
							.actions-divider {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
