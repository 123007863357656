@import "../../../Config";

.dashboard-page-container {
	height: 100%;
	width: 100%;

	.dashboard-page-layout {
		&.ant-layout {
			height: 100%;
			width: 100%;

			.dashboard-page-sider {
				&.ant-layout-sider {
					overflow: auto;
					height: 100%;
					position: fixed;
					left: 0px;
					top: 0px;
					z-index: 1;
					box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
					transition: all 0.2s ease;

					.ant-layout-sider-children {
						overflow-y: hidden;

						.dashboard-page-sider-logo {
							height: 128px;
							background-image: url('https://schedulify.app/images/login/ProfileCover.webp'), url('https://schedulify.app/images/login/ProfileCover.jpg');
							background-repeat: no-repeat;
							background-size: cover;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;
							transition: all 0.2s ease;

							.gravatar {
								height: 96px;
								width: 96px;
								line-height: 96px;
								border-radius: 50%;
								transition: all 0.2s ease;
							}
						}

						.dashboard-page-sider-menu {
							&.ant-menu {
								height: calc(100% - 272px);
								overflow: auto;
								transition: all 0.2s ease;
								overflow-y: auto;

								.ant-menu-submenu {
									.ant-menu-submenu-title {
										position: relative;
										margin: 0px auto;
										height: 48px;
										line-height: 48px;

										.anticon {
											height: 48px;
											line-height: 48px;
										}
									}

									.ant-menu {
										&.ant-menu-sub {
											.ant-menu-item {
												margin: 0px auto;
												height: 48px;
												line-height: 48px;

												.anticon {
													height: 48px;
													line-height: 48px;
												}
											}
										}
									}

									&.ant-menu-submenu-inline,
									&.ant-menu-submenu-vertical {
										&.ant-menu-submenu-selected {
											color: $themePrimaryColor;

											.ant-menu-submenu-title {
												color: $themePrimaryColor;
												}

											.ant-menu-item {
												&.ant-menu-item-selected {
													color: $themePrimaryColor;

													a {
														color: $themePrimaryColor;
													}
												}
											}
										}
									}

									&.ant-menu-submenu-vertical {
										&.ant-menu-submenu-selected {
											color: $themeSecondaryTextColor;
											background-color: $themePrimaryColor;

											.ant-menu-submenu-title {
												color: $themeSecondaryTextColor;
											}

											a {
												color: $themeSecondaryTextColor;
											}
										}

										&.ant-menu-submenu-open,
										&.ant-menu-submenu-active {
											&:not(.ant-menu-submenu-selected) {
												.ant-menu-submenu-title {
													color: $themeSecondaryTextColor;
												}

												a {
													color: $themeSecondaryTextColor;
												}
											}
										}
									}
								}

								.ant-menu-item {
									margin: 0px auto;
									height: 48px;
									line-height: 48px;

									.anticon {
										height: 48px;
										line-height: 48px;
									}
								}
							}
						}

						.dashboard-page-sider-menu-extra-container {
							height: 144px;
							border-top: 1px solid $themeBorderColor;

							.dashboard-page-sider-menu-extra {
								&.ant-menu {
									height: 144px;
									overflow: auto;
									transition: all 0.2s ease;
									background-color: rgba($color: $themePrimaryColor, $alpha: 0.25);

									.ant-menu-item {
										margin: 0px auto;
										height: 48px;
										line-height: 48px;

										.anticon {
											height: 48px;
											line-height: 48px;
										}

										&:not(:last-child) {
											border-bottom: 1px solid $themeBorderColor;
										}
									}
								}
							}
						}
					}

					&.ant-layout-sider-collapsed {
						.ant-layout-sider-children {

							.dashboard-page-sider-logo {
								height: 64px;

								.gravatar {
									height: 48px;
									width: 48px;
									line-height: 48px;
									border-radius: 50%;
								}
							}

							.dashboard-page-sider-menu {
								&.ant-menu {
									height: calc(100% - 208px);
								}
							}
						}
					}
				}
			}

			.dashboard-page-wrapper {
				&.ant-layout {
					height: 100%;
					width: 100%;
					overflow: auto;
					transition: all 0.2s ease;
					background-color: $themeContainerBackgroundColor;

					.dashboard-page-header {
						&.ant-layout-header {
							background: $themeWhiteColor;
							height: 64px;
							line-height: 64px;
							width: 100%;
							padding: 0px;
							display: flex;
							align-items: center;
							position: relative;

							.dashboard-page-sider-trigger-wrapper {
								padding: 0px;
								text-align: center;
								width: 64px;
								position: absolute;
								color: $themeSecondaryTextColor;
							}

							.dashboard-page-header-wrapper {
								padding: 0px;
								text-align: center;
								width: 100%;

								a {
									color: $themeSecondaryTextColor;
								}

								.logo {
									height: 36px;
									width: 36px;
									margin-right: 12px;
								}
							}
						}
					}

					.dashboard-page-content {
						&.ant-layout-content {
							height: 100%;
							width: calc(100% - 32px);
							background: $themeWhiteColor;
							margin: 16px;
							padding: 24px;
							overflow: auto;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 575px) {
	.dashboard-page-container {
		.dashboard-page-layout {
			&.ant-layout {
				.dashboard-page-wrapper {
					&.ant-layout {
						.dashboard-page-header {
							&.ant-layout-header {
								border-bottom: 1px solid $themeBorderColor;
							}
						}

						.dashboard-page-content {
							&.ant-layout-content {
								width: 100%;
								margin: 0px;
								padding: 24px;
							}
						}
					}
				}
			}
		}
	}
}

.dashboard-sider-submenu {
	&.ant-menu-submenu {
		.ant-menu {
			padding: 6px 0px;

			.ant-menu-item-group-title {
				padding-left: 24px;
			}

			.ant-menu-item {
				margin: 0px auto;
				height: 44px;
				line-height: 44px;

				.anticon {
					height: 44px;
					line-height: 44px;
				}
			}
		}
	}
}