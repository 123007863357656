@import "./Config";

.app-error-result {
	&.ant-result {

		.ant-result-title,
		.ant-result-subtitle {
			color: $themePrimaryTextColor;
		}

		.ant-result-content {
			background-color: $themeContainerBackgroundColor;
			margin-top: 48px;

			.ant-typography {
				&:not(.link) {
					color: $themePrimaryTextColor;
				}

				&:last-child {
					margin-bottom: 0px;
				}
			}

			.error-icon {
				color: $themeRedColor;
			}
		}
	}
}