@import "../../../Config";

.contact-us-form,
.bug-report-form,
.feature-request-form {
	&.ant-form {
		max-width: 575px;
		margin: 0px auto;
		padding: 36px 36px 72px 36px;
		font-size: 16px;

		.contact-form-title {
			font-size: 32px;
			margin-top: 0px;
			margin-bottom: 24px;
			color: $themeSecondaryTextColor;
		}

		.contact-form-button-container {
			text-align: center;

			.contact-form-button {
				min-width: 200px;
			}
		}

		.ant-form-item {
			&:not(.ant-form-item-has-error) {
				.ant-form-item-control {
					.ant-form-item-explain {
						color: $themeSecondaryTextColor;
					}
				}
			}
		}
	}
}